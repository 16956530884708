<template>
    <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
      <v-card class="pa-4">
         
        <v-toolbar flat color="white">
          <v-toolbar-title>Asiento contable :: {{datosAsiento.acctg_trans_id}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical> </v-divider>          
          
          <v-btn v-if="(datosAsiento.is_posted=='N' || datosAsiento.is_posted==null )" :to='`/asientoContableForm/${datosAsiento.acctg_trans_id}`' class="mx-4" dark small color="blue"><v-icon left small>mdi-pencil</v-icon>Editar</v-btn>          
          <v-btn @click="anularAsiento()" class="mx-4" dark small color="blue"><v-icon left small>mdi-cancel</v-icon>Anular</v-btn>
          <v-btn @click="copiarAsiento()" class="mx-4" dark small color="blue"><v-icon left small>mdi-content-copy</v-icon>Copiar</v-btn>
          <v-btn @click="imprimirAsiento()" class="mx-4" dark small color="blue"><v-icon left small>mdi-printer</v-icon>Imprimir</v-btn>
          <v-btn to='/crearAsientoSimple' class="mx-4" dark small color="blue"><v-icon left  small>mdi-plus</v-icon>Crear nuevo</v-btn>
          <v-btn v-if="datosAsiento.is_posted=='N' || datosAsiento.is_posted==null" @click="procesarAsiento()" class="mx-4" dark small color="blue"><v-icon left small>mdi-cogs</v-icon>Procesar</v-btn>
          <v-btn @click="reactivarAsiento()" v-if="puedoActivarAsiento && datosAsiento.is_posted=='Y'" dark color="blue" small ><v-icon>mdi-redo</v-icon>Activar</v-btn>

        </v-toolbar>
       
     
        <v-row>
            <v-col cols="6">
                <v-text-field readonly label="Num. transacción" v-model="datosAsiento.acctg_trans_id" />
            </v-col>
            <v-col cols="6">
                <v-text-field readonly label="Tipo de transacción" v-model="datosAsiento.acctg_trans_type" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-text-field readonly label="Fecha de transacción" v-model="datosAsiento.transaction_date" />
                
            </v-col>
            
            <v-col cols="6">
                <v-text-field  readonly label="Fecha de registro" v-model="datosAsiento.posted_date" />                
            </v-col>
        
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-text-field readonly label="Procesado" v-model="datosAsiento.is_posted" />
                
            </v-col>
            
            <v-col cols="6">
                <v-text-field readonly label="Creado por el usuario" v-model="datosAsiento.created_by_user_login" />
            </v-col>
        
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-text-field readonly label="Descripción" v-model="datosAsiento.description" />                
            </v-col>        
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-text-field readonly label="Participante" v-model="datosAsiento.party_name" />                
            </v-col>
            <v-col cols="6">
                <v-text-field readonly label="Rol del participante" v-model="datosAsiento.role_name" />                
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" v-if="datosAsiento.payment_id != null && datosAsiento.acctg_trans_type_id != 'RETENCION_EMITIDA'">
                 
                <v-text-field readonly label="Pago" v-model="datosAsiento.payment_id" >
                <template slot="append" >
                    <v-btn v-if="datosAsiento.acctg_trans_type_id=='INCOMING_PAYMENT'" target="_blank" icon small :to="`/ver-pago/${datosAsiento.payment_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                    <v-btn v-if="datosAsiento.acctg_trans_type_id=='OUTGOING_PAYMENT'" target="_blank" icon small :to="`/ver-pago-emitido/${datosAsiento.payment_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                </template>
                </v-text-field> 
            </v-col>

            <v-col cols="6" v-if="datosAsiento.invoice_id != null">
                
                <v-text-field readonly label="Factura" v-model="datosAsiento.codigo_establecimiento + '-' + datosAsiento.codigo_punto_emision + '-' + datosAsiento.invoice_number" >
                <template slot="append" >
                    <v-btn v-if="datosAsiento.parent_invoice_type_id=='SALES_INVOICE'" target="_blank" icon small :to="`/factura-venta/${datosAsiento.invoice_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                    <v-btn v-if="datosAsiento.parent_invoice_type_id=='PURCHASE_INVOICE'" target="_blank" icon small :to="`/ver-factura-compra/${datosAsiento.invoice_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                </template>
                </v-text-field>
            </v-col>

            <v-col cols="6" v-if="datosAsiento.fin_account_trans_id != null">
                
                <v-text-field readonly label="Caja" v-model="datosAsiento.caja_id" >
                <template slot="append" >
                    <v-btn  target="_blank" icon small :to="`/ver-caja/${datosAsiento.caja_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>                    
                </template>
                </v-text-field>
            </v-col>

            <v-col cols="6">
                <v-text-field label="Transacción" v-model="datosAsiento.fin_account_trans_id" />                
            </v-col>

            <v-col cols="6" v-if="datosAsiento.payment_id != null && datosAsiento.acctg_trans_type_id =='RETENCION_EMITIDA'">
                
                <v-text-field label="Retención" v-model="datosAsiento.payment_id" >
                    <template slot="append" >
                    <v-btn target="_blank" icon small :to="`/retencion-emitida/${datosAsiento.payment_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                    
                </template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-toolbar color="#cecece" rounded height="54" >
        <v-row>
            <v-col class="ml-4 " cols="4">                
                
            </v-col>
            <v-col cols="2" class="mt-2">
                TOTAL DEBE:  {{totalDebito}}  
            </v-col>
            <v-col cols="2" class="mt-2">
                TOTAL CREDITO:  {{totalCredito}}  
            </v-col>

            <v-col cols="2" class="mt-2">
                DIFERENCIA:  {{(totalDebito - totalCredito).toFixed(2)}}  
            </v-col>
        </v-row>
        </v-toolbar>
        <v-data-table :items-per-page="itemsPaginaAte" :items="listaAte" :headers="headersAte" hide-default-footer />
    </v-card>
</div>
</template>


<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 


export default {
    name: 'AsientoContableVista',
    props: {
        

    },
    components: {

    },
    data: () => ({
        cajaNum: "",
        cajaFecha: "",
        cajaEstado: "",
        cajaUsuario: "",
        headersAte: [
            { text: "Transaccion", value: "acctg_trans_id"},
			{ text: "Seq", value: "acctg_trans_entry_seq_id" },						
			{ text: "Cuenta", value: "cuenta_nombre" },			 
            { text: "Descripcion", value: "description" },			 
			{ text: "Monto", value: "amount" },
			{ text: "D/C", value: "debit_credit_flag" },
			
		],
       
        listaAte: [],
        options: {},
        overlay: false,
        search: "",
        uid: "",
        datosAsiento: {},


        pageCount: 1,
        currentPage: 1,
        itemsPerPage: 20,
        itemsPaginaAte: 1000

    }),
    computed: {
        ...mapState('master', ['loadingTable']),
        ...mapState('contabilidad', ['menu']),
        ...mapState('pos', ['menuPos']),
        ...mapGetters('access', ['puedoActivarAsiento']),

        totalDebito () {
            let total = 0;            

            for(let i=0; i < this.listaAte.length ; i++) {
                if(this.listaAte[i].debit_credit_flag=='D') {
                    total += parseFloat(this.listaAte[i].amount)
                }
                
            }
            return total.toFixed(2);
        },
        totalCredito() {
            
            let total = 0;

            for(let i=0; i < this.listaAte.length ; i++) {
                
                if(this.listaAte[i].debit_credit_flag=='C') {
                    total += parseFloat(this.listaAte[i].amount)
                }
            }
            return total.toFixed(2)

        }

    }
    , methods: {
        ...mapMutations('master', ['setUrl', 'setOverlay', 'setMenu', 'setLoadingTable']),
        ...mapActions('master', ['requestApi']),

        cargarAsiento(id) {
            this.overlay=true
            this.setUrl('acctg-transaction/'+id)

            this.requestApi({
                method: 'GET',
                data: {
                                  
                }
            }).then(res => {                
                //console.log(res)
                this.datosAsiento = res.data.detail.acctg_trans;
                this.listaAte = res.data.detail.acctg_trans_entries;
                this.overlay = false;
            }).catch(()=>{
                this.overlay = false;
            });
        },
        
        handlePageChange(value) {
            this.currentPage = value
            
        },

        copiarAsiento() {
            
            this.setUrl('acctg-transaction')
            this.overlay=true;
            this.requestApi({
                method: 'POST',
                data: {
                    acctg_trans_id : this.$route.params.acctgTransId,
                    accion: "copiarAsiento"       
                }
            }).then(res => {                
                
                this.overlay = false;
                this.$router.push("/asientoContableForm/"+res.data.detail.acctgTransId)

            }).catch(()=>{
                this.overlay = false;
            });

        },
        imprimirAsiento() {
            
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    acctg_trans_id : this.$route.params.acctgTransId,
                    accion: "imprimirAsiento"       
                }
            }).then(res => {                
                
                var a = document.createElement("a");
                a.href = "data:" + res.data.detail.ContentType + ";base64," + res.data.detail.datos;
                a.download = res.data.detail.nombreArchivo;
                a.click();

            });

        },
        anularAsiento() {
            this.overlay = true
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    acctg_trans_id : this.$route.params.acctgTransId,
                    accion: "anularAsiento"       
                }
            }).then(res => {                                
                //this.cargarAsiento(this.$route.params.acctgTransId)                
                this.overlay = false                
                this.$router.push("/asientoContableForm/"+res.data.detail.acctgTransId);
            }).catch(()=>{
                this.overlay = false
            });
        },
        procesarAsiento() {
            
            this.overlay = true;
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    acctg_trans_id : this.$route.params.acctgTransId,
                    accion: "procesarAsiento"       
                }
            }).then(res => {                
                //console.log(res)
                this.cargarAsiento(this.$route.params.acctgTransId)

                this.overlay = false;
            }).catch(() => {
                this.overlay = false;
            });
        },

        reactivarAsiento() {
            
            this.overlay = true;
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    acctg_trans_id : this.$route.params.acctgTransId,
                    accion: "reactivarAsiento"       
                }
            }).then(res => {                
                //console.log(res)
                this.cargarAsiento(this.$route.params.acctgTransId)

                this.overlay = false;
            }).catch(() => {
                this.overlay = false;
            });
        }

    },

    watch: {

        options: {
            handler() {
                //this.cargarAsiento()
            },
            deep: true,
        },
    },
    mounted() {        
        this.cargarAsiento(this.$route.params.acctgTransId)
    },



}
</script>